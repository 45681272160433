// ActivityMenu.js
import React from 'react';

function EnrichmentCourse() {
  return (
    <div>
      <h1>兴趣班</h1>
    </div>
  );
}

export default EnrichmentCourse;
