// ActivityMenu.js
import React from 'react';
import './OverseasStudies.css'; // Import the stylesheet


function OverseasStudies() {
  return (
    <div className="overseasStudiesContainer">
         <h1>新加坡政府高中的J-PACT考试信息</h1>
    <p>新加坡政府高中提供了多元化和高质量的教育，吸引了许多国际学生。对于有意向升读新加坡政府高中的学生来说，了解J-PACT考试及其相关流程是非常重要的。</p>
    
    <h2>J-PACT考试概述</h2>
    <p>J-PACT，是一项专为国际学生设计的标准化考试。它旨在评估学生在英语和数学两个科目上的能力，作为申请新加坡政府高中的一个重要依据。考试在新加坡本地进行，考试时间在十月份开始。</p>

    <h2>J-PACT考试流程</h2>
    <ul>
        <li><strong>报名：</strong>在每年的九月份开始，学生需要在规定的时间内完成J-PACT考试的报名。</li>
        <li><strong>考试：</strong>考试在十月份开始举行，考试科目包括英语和数学。考试在新加坡当地进行，并且在一天之内完成。</li>
        <li><strong>成绩放榜：</strong>考试成绩在十一月份公布。</li>
        <li><strong>申请入学：</strong>一旦拿到J-PACT考试成绩，学生可以在十二月份通过电子邮件的方式向心仪的新加坡政府高中申请入学。</li>
        <li><strong>入学结果公布：</strong>对于本地学生，入学结果在次年二月初公布。本地学生可针对分配结果上诉，上诉结果在二月中旬后揭晓。而对于国际学生，入学结果则要等待本地学生分配完学校后，才能确定。</li>
    </ul>

    <h2>准备J-PACT考试的注意事项</h2>
    <ul>
        <li><strong>准备充分：</strong>J-PACT考试内容涵盖了英语和数学两个科目，因此提前做好准备是非常重要的。可以通过参加模拟考试或辅导课程来加强准备。</li>
        <li><strong>了解考试格式：</strong>熟悉考试的格式和题型有助于学生在考试中发挥更好。例如，英语部分包括阅读理解、写作等，而数学部分则包括选择题和计算题。</li>
        <li><strong>时间管理：</strong>考试当天的时间管理非常关键。学生应该学会如何在有限的时间内有效地完成所有题目。</li>
        <li><strong>健康和心理准备：</strong>保持良好的身体健康和积极的心态对考试同样重要。考前保持适当的休息和饮食，有助于提高考试表现。</li>
    </ul>

    <p>总之，对于有意向通过J-PACT考试升读新加坡政府高中的学生来说，了解考试流程、充分准备和保持良好的身心状态是关键。通过这些步骤，学生可以更好地准备和适应新加坡的教育环境。</p>

    </div>
  );
}

export default OverseasStudies;
