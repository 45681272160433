import React from 'react';

function Footer() {
  const currentYear = new Date().getFullYear(); // Gets the current year

  return (
    <footer>
      <p>© {currentYear} 婉煊教育</p>
      <p>Contact Us: <a href="mailto:admin@wanxuaneducation.com">admin@wanxuaneducation.com</a> </p>
      <p><a href="http://beian.miit.gov.cn/">闽ICP备2024035325号</a></p>
    </footer>
  );
}

export default Footer;
